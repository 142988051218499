<template>
  <basicData v-if="email !== null" :email="email"></basicData>
</template>

<script>
import basicData from '@/components/emailDetails/basic-data';

export default {
  components: {
    basicData
  },

  computed: {
    emails() {
      return [
        {
          id: 1,
          sender: 'Christian Hake',
          recipient: 'Timo Denger',
          cc: 'Sven Endmann',
          bcc: 'Marc Grein',
          subject: 'Guten Morgen',
          body: 'Sehr geehrte Herren,\n\nich wünsche Ihnen einen guten Morgen und freue mich auf unser heutiges gemeinsames Meeting.\n\nViele Grüße,\nChristian Hake',
          status: 'Gesendet',
          sendingDateTime: '13.04.2023 08:00:00'
        },
        {
          id: 2,
          sender: 'Christian Hake',
          recipient: 'Sven Endmann',
          cc: 'Timo Denger',
          bcc: 'Marc Grein',
          subject: 'Guten Abend',
          body: 'Sehr geehrte Herren,\n\nich wünsche Ihnen einen guten Abend und bedanke mich für unser heutiges gemeinsames Meeting.\n\nViele Grüße,\nChristian Hake',
          status: 'Ausstehend',
          sendingDateTime: '13.04.2024 18:00:00'
        }
      ];
    },

    email() {
      return this.emails.find((x) => x.id == this.$route.params.id);
    }
  }
};
</script>
