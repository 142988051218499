<template>
  <v-card flat>
    <v-card-text>
      <v-row>
        <v-col cols="6" sm="6" md="4">
          {{ $t('emailDetails.id') }}
        </v-col>
        <v-col cols="6" sm="6" md="8">
          <v-text-field
            v-model="email.id"
            :placeholder="$t('emailDetails.sender')"
            filled
            disabled
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6" sm="6" md="4">
          {{ $t('emailDetails.sender') }}
        </v-col>
        <v-col cols="6" sm="6" md="8">
          <v-text-field
            v-model="email.sender"
            :placeholder="$t('emailDetails.sender')"
            filled
            readonly
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6" sm="6" md="4">
          {{ $t('emailDetails.recipient') }}
        </v-col>
        <v-col cols="6" sm="6" md="8">
          <v-text-field
            v-model="email.recipient"
            :placeholder="$t('email.recipient')"
            filled
            readonly
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6" sm="6" md="4">
          {{ $t('emailDetails.sendingDateTime') }}
        </v-col>
        <v-col cols="6" sm="6" md="8">
          <v-text-field
            v-model="email.sendingDateTime"
            :placeholder="$t('email.sendingDateTime')"
            filled
            readonly
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6" sm="6" md="4">
          {{ $t('emailDetails.subject') }}
        </v-col>
        <v-col cols="6" sm="6" md="8">
          <v-text-field
            v-model="email.subject"
            :placeholder="$t('emailDetails.subject')"
            filled
            readonly
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6" sm="6" md="4">
          {{ $t('emailDetails.cc') }}
        </v-col>
        <v-col cols="6" sm="6" md="8">
          <v-text-field
            v-model="email.cc"
            :placeholder="$t('emailDetails.cc')"
            filled
            readonly
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6" sm="6" md="4">
          {{ $t('emailDetails.bcc') }}
        </v-col>
        <v-col cols="6" sm="6" md="8">
          <v-text-field
            v-model="email.bcc"
            :placeholder="$t('emailDetails.bcc')"
            filled
            readonly
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6" sm="6" md="4">
          {{ $t('emailDetails.status') }}
        </v-col>
        <v-col cols="6" sm="6" md="8">
          <v-autocomplete
            v-model="email.status"
            :items="emailStatusOptions"
            filled
            hide-details
          ></v-autocomplete>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6" sm="6" md="4">
          {{ $t('emailDetails.body') }}
        </v-col>
        <v-col cols="6" sm="6" md="8">
          <tip-tap v-model="email.body" :disabled="true" />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import TipTap from '@/components/editor/TipTap';

export default {
  components: {
    TipTap
  },
  props: {
    email: {
      type: Object,
      required: true
    }
  },
  computed: {
    emailStatusOptions() {
      return [
        'Abgebrochen',
        'Wird gesendet',
        'Ausstehend',
        'Gesendet',
        'Fehlgeschlagen',
        'Empfangen'
      ];
    }
  }
};
</script>
